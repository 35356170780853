<template>
  <div>
    <div id="app" :style="{ 'padding-top': statusLogin() ? '7.5rem' : '2rem' }" v-if="!checkRouteLawyer">
      <div id="top-bar" class="d-flex" v-if="isLoggedIn">
        <img @click="goMakeQuotation()" src="./assets/logo.png" id="top-bar-logo" class="pointer" />

        <div class="wrapper-buttons ml-auto d-flex align-items-center">
          <span v-if="activeButtonPanel() && accessPanel()" @click="goPanelDashboard()"
            class="btn btn-outline-secondary mr-2" type="button" id="dropdownMenuButton">
            <i class="fa fa-angle-left"></i> <span class="d-none d-lg-inline-block ml-2">Painel</span>
          </span>

          <span v-if="!activeButtonPanel() && $can.any(['quotation-store', 'quotation-update', 'quotation-edit'])"
            @click="goMakeQuotation()" class="btn btn-outline-primary mr-2">
            <i class="fa fa-cart-plus"></i><span class="d-none d-md-inline"> Faça uma cotação</span>
          </span>

          <div class="dropdown">
            <button @blur="toggleDropdown(false)" @click="toggleDropdown(true)"
              class="btn btn-outline-primary dropdown-trigger mr-1" :class="{ 'show': show }" type="button"
              id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" :aria-expanded="show">
              <span class="d-none d-lg-inline-block mr-2">Olá, {{ firstName }}</span> <i class="fa fa-ellipsis-v"></i>
            </button>
            <div class="dropdown-menu-custom dropdown-menu-right" :class="{ 'show': show }"
              aria-labelledby="dropdownMenuButton">
              <span @mousedown="logout()" class="dropdown-item" style="color:white;"><i class="fa fa-fw fa-sign-out-alt"></i> Sair</span>
            </div>
          </div>
        </div>

      </div>
      <img src="./assets/logo.png" alt="Logotipo FINN Seguros" loading="lazy" class="d-block img-white mx-auto mb-4"
        style="max-width: 100%; width: 250px;" v-else>
        <a :href="'https://api.whatsapp.com/send?l=pt&amp;phone=' + numberWhatsapp" target="_blank">
          <img :src="require('@/assets/whatsapp-icon.png')" class="whatsapp" data-selector="img">
        </a>

      <router-view />
    </div>

    <div v-else>
      <appLawyer/>
    </div>

  </div>
</template>
<script>

import authenticationService from '@/services/authentication';
import enumContactInfo from "@/enums/contactInfo";
import appLawyer from "@/components/Lawyer/App";

export default {
  name: "App",
  components: {
    appLawyer,
  },
  computed: {
    firstName() {
      var name = this.$store.getters.user.name ? this.$store.getters.user.name.split(' ').slice(0, -1).join(' ') : '';
      return name.charAt(0).toUpperCase() + name.slice(1);
    },
    checkRouteLawyer() {
      return this.$route.path.includes("advogado")
    }
  },
  data: () => ({
    show: false,
    numberWhatsapp: ('+55 ' + enumContactInfo.APP_FINN_TELEPHONE).replace(/\D/g, ''),
    isLoggedIn: false
  }),
  mounted() {
    this.checkTokenStatus();
  },
  updated() {
    this.checkTokenStatus();
  },
  methods: {
    toggleDropdown(boolean) {
      this.show = boolean
    },
    statusLogin() {
      return Object.keys(this.$store.getters.user).length > 0;
    },
    activeButtonPanel() {
      if (this.$route.matched[0]) {
        return this.$route.matched[0].components.default.name != 'Panel'
      }
    },
    goMakeQuotation() {
      this.$router.push({ name: 'quotation' })
    },
    goPanelDashboard() {
      this.$router.push({ name: 'dashboard' })
    },
    logout() {
      this.$store.commit("SET_PREVENT_REDIRECTION");
      authenticationService.logout().then(() => {
        this.toggleDropdown(false)
        this.$router.push({ name: "login" });
      });
    },
    accessPanel() {
      return (authenticationService.accessPanel() || authenticationService.isMaster())
    },
    checkTokenStatus() {
      this.isLoggedIn = authenticationService.isValidToken();
    },
  }
};
</script>

<style lang="scss">
</style>
